import React from "react"
import styled from "styled-components"
import { primaryColor, secondaryColor } from "../static/styles/colors"
import { StaticImage } from "gatsby-plugin-image"
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube"
import { FaFacebook } from "@react-icons/all-files/fa/FaFacebook"
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram"
// import { Link } from "gatsby"
import SimpleMailForm from "./simple-mail-form"

const ContactFooter = () => {
  return (
    <BGLinearGradient id="contactFormStart">
      <Separator />
      <DropShadow className="container w-75">
        <div className="row">
          <div className="col-xl-5 bg-light"></div>
          <div className="col-xl-7 p-0">
            <NoListStyle
              className="nav nav-tabs p-0 ms-auto d-flex flex-row"
              id="myTab"
              role="tablist"
              style={{ borderBottom: "none" }}
            >
              <li
                className="nav-item p-0 flex-fill bg-dark"
                role="presentation"
              >
                <a
                  className="nav-link p-3 border-0 rounded-0 active text-uppercase h4 text-light h-100"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  href="#contact"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="true"
                >
                  Kontakt
                </a>
              </li>
              <li
                className="nav-item p-0 flex-fill bg-dark"
                role="presentation"
              >
                <a
                  className="nav-link p-3 border-0 rounded-0 text-uppercase h4 text-light"
                  id="voucher-tab"
                  data-bs-toggle="tab"
                  href="#voucher"
                  role="tab"
                  aria-controls="voucher"
                  aria-selected="false"
                >
                  Download
                  <br /> Flyer
                </a>
              </li>
            </NoListStyle>
          </div>
        </div>
        <div className="row flex-xl-row-reverse">
          <div className="col-xl-7 bg-primary">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active text-light"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div className="container mt-5">
                  <div className="row">
                    <div className="col-md-8 mx-auto">
                      <StaticImage
                        src="../images/contact/kontakt.jpg"
                        alt="Kontakt"
                        layout="fullWidth"
                        quality={95}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="none"
                      />
                    </div>
                    <div className="col-md-8 py-3 mx-auto">
                      <a
                        className="btn btn-success text-dark text-uppercase w-100"
                        href="https://fachhandwerk360.appointlet.com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Jetzt kostenloses Online Gespräch vereinbaren
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade text-light"
                id="voucher"
                role="tabpanel"
                aria-labelledby="voucher-tab"
              >
                <div className="container mt-5 fs-5">
                  <p>
                    Geben Sie hier Ihre E-Mail ein um unseren{" "}
                    <span className="fw-bold">kostenlosen Flyer</span> zu
                    erhalten.
                  </p>
                  <SimpleMailForm request={"Flyer"} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-5 bg-light">
            <div className="container text-secondary fs-5 fw-bold p-3 pt-sm-5 pb-sm-5 text-sm-start text-center">
              <p>Wir beraten Sie gerne</p>
              <StaticImage
                src="../images/FHW360_logo_dark_lang.png"
                alt="Fachhandwerk360 Logo"
                width={500}
                quality={95}
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
              />
              <p className="pt-sm-0 pt-3">
                Rheinstraße 28
                <br />
                55432 Niederburg
                <br />
                Deutschland
              </p>
              <p>Tel.: 06744 9491087</p>
              <p className="fw-light h6 mt-5">Folgen Sie uns</p>
              <a
                href="https://www.youtube.com/channel/UCku1c-_g11pH5DsI2wRdBjA"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size="3rem" className="me-3" color={primaryColor} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=100082946961422"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook size="3rem" className="me-3" color={primaryColor} />
              </a>
              <a
                href="https://www.instagram.com/fachhandwerk360/"
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagram
                  size="3rem"
                  className="me-3"
                  color={primaryColor}
                />
              </a>
            </div>
          </div>
        </div>
      </DropShadow>
      <Separator />
    </BGLinearGradient>
  )
}

const BGLinearGradient = styled.div`
  background: linear-gradient(${primaryColor}, ${secondaryColor});
  box-shadow: 0px -5px 20px black;
`

const Separator = styled.hr`
  border: 5px solid transparent;
  padding: 3% 0px 3% 0px;
  margin: 0px;
  opacity: 0;
`

const NoListStyle = styled.ul`
  & > li::marker {
    content: "";
  }
  & > li {
    list-style-image: none;
  }
`

const DropShadow = styled.div`
  filter: drop-shadow(0 0 0.25rem black);
`

export default ContactFooter
